import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Background = styled.img`
  z-index: -1;
  object-fit: contain;
  width: 100vw;
`;

export const Body = styled.div`
  background-color: white;
  width: calc(80.19vw - (4.375vw * 2));
  height: 74.06vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-top: -7.500vw;
  position: relative;
  padding: 3.313vw 4.375vw;
  flex-wrap: wrap;
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

export const Title = styled.div`
  font-family: BigCaslon-Regular;
  font-size: 3.500vw;
  color: #2C2C2C;
  letter-spacing: 1.56px;
  margin-bottom: 1.938vw;
`;

export const SubTitle = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 900;
  font-size: 1.125vw;
  color: #2C2C2C;
  letter-spacing: 1.08px;
  margin-bottom: 1.938vw;
  text-transform: uppercase;
`;

export const Text = styled.div`
  font-family: calluna;
  font-family: 300;
  font-size: 1.125vw;
  color: #2C2C2C;
  letter-spacing: 0;
  line-height: 1.375vw;
  width: 28.94vw;
`;

export const Image = styled.img`
  object-fit: contain;
  width: 38.75vw;
  height: 38.75vw;
`;