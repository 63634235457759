import styled from 'styled-components';

export const Container = styled.div`
  height: 471px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
`;

export const Title = styled.div`
  font-family: BigCaslon-Regular;
  font-size: 40px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 75px;
  text-shadow: 2px 2px 16px rgba(18,76,86,0.48);
  margin-bottom: 25px;
`;

export const Button = styled.div`
  background: #7DBEC8;
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-align: center;
  padding: 15px 10px;
  width: fit-content;
  margin: 0 auto;
`;