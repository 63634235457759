import React, { useContext } from 'react';
import { RichText } from 'prismic-reactjs';
import { AppContext } from '../../../components/AppProvider';
import {
  Container, Background, Body, Title, SubTitle, Text, TextSection, Image,
} from './styled';
import picture_1 from './picture_1.png';
import picture_2 from './picture_2.jpeg';
import CTA from './CTA';

function AboutUs() {
  const { data } = useContext(AppContext);
  const {
    section_content = [], background_image, title,
  } = data;
  const [section1, section2] = section_content;
  return (
    <Container>
      <Background src={background_image?.url} />
      <Body>
        <TextSection>
          <Title>{title}</Title>
          <SubTitle>{section1?.section_title}</SubTitle>
          <Text><RichText render={section1?.section_body} /></Text>        </TextSection>
        <TextSection>
          <Image src={picture_1} />
        </TextSection>
        <TextSection style={{ marginTop: '-11.75vw' }}>
          <Image src={picture_2} />
        </TextSection>
        <TextSection>
          <SubTitle>{section2?.section_title}</SubTitle>
          <Text><RichText render={section2?.section_body} /></Text>
        </TextSection>
      </Body>
      <CTA />
    </Container>
  );
}

export default AboutUs;
