import React from 'react';
import CTA from '../CTA';
import {
  Container, Background, Body, Title, SubTitle, Text, TextSection, Image,
} from './styled';
import image from './background_image.png';
import picture_1 from './picture_1.png';
import picture_2 from './picture_2.jpeg';
import CTABackground from './cta.png';

function AboutUs() {
  return (
    <Container>
      <Background src={image} />
      <Body>
        <TextSection>
          <Title>About Us</Title>
          <SubTitle>The team behind So Bridely</SubTitle>
          <Text>So Bridely was born because two wedding professionals (and best friends), Alaina and Jazmin, wanted to create a platform that could make impossible wedding dress scenarios possible. We have witnessed the ins and outs of the wedding industry for the last 5 years and we can say with certainty, WE HAVE SEEN IT ALL! From the brides who fall deeply in love with their $10k Pinterest find, to brides who must plan their wedding in six months or less, our goal at So Bridely is to make every bride’s dress finding experience fun and easy!</Text>
        </TextSection>
        <TextSection>
          <Image src={picture_1} />
        </TextSection>
        <TextSection>
          <Image src={picture_2} />
        </TextSection>
        <TextSection>
          <SubTitle>Our Mission</SubTitle>
          <Text>Celebrating love and establishing meaningful relationships with our brides and boutiques are what’s most important to our team, because really, that’s why we’re all here right? Finding the perfect wedding dress is such a special moment, and should be treated as such, whether you say yes in a store or online! So let’s pop something sparkly and toast to you marrying your soulmate while wearing the gown that makes you feel SO BEAUTIFUL, SO CONFIDENT, and SO BRIDELY!</Text>
        </TextSection>
      </Body>
      <CTA img={CTABackground} title={`The So Bridely Story:\nWhy we started this company`} buttonText="Read Full Post" />
    </Container>
  );
}

export default AboutUs;
