import React from 'react';
import { navigate } from '@reach/router';
import Image from '../../ImageBasic';
import {
  Container, Title, Button,
} from './styled.js';
import Background from './Background.png';

function CTA({
  img, title = 'Find your dream wedding gown.', buttonText = 'CREATE MY ACCOUNT',
}) {
  return (
    <Container>
      <Image style={{
        maxWidth:'100%', height: '100%',
        position: 'absolute', top: 0, left: 0, zIndex: -1, objectFit: 'cover',
      }} src={img || Background} />
      <Title>{title}</Title>
      <Button onClick={() => navigate('/create-account')}>{buttonText}</Button>
    </Container>
  );
}

export default CTA;