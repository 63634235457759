import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Background = styled.img`
  z-index: -1;
  object-fit: contain;
  width: 100vw;
`;

export const Body = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  position: relative;
  flex-wrap: wrap;
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 30px 0;
`;

export const Title = styled.div`
  font-family: BigCaslon-Regular;
  font-size: 56px;
  color: #124C56;
  margin: 0 20px;
  letter-spacing: 1.56px;
`;

export const SubTitle = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 900;
  font-size: 18px;
  color: #124C56;
  letter-spacing: 1.08px;
  text-transform: uppercase;
  margin: 0 20px;
  margin-bottom: 15px;
`;

export const Text = styled.div`
  font-family: calluna; 
  font-weight: 300;
  font-size: 18px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0 20px;
`;

export const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: auto;
`;