import React from 'react';
import {
  Container, Body, Button, BackgroundImage, Text,
} from './styled';
import Background from './Background.png';
import NextArrow from './NextArrowIcon.svg';

function CTA() {
  return (
    <Container>
      <BackgroundImage src={Background} />
      <Body>
        <Text>The So Bridely Story:<br />Why we started this company</Text>
        <Button>
          <div>Read the full post</div>
          <img src={NextArrow} />
        </Button>
      </Body>
    </Container>
  );
}

export default CTA;
