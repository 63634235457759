import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
//components
import Layout from '../components/Layout';
import AboutUsDesktop from '../components/Desktop/AboutUs';
import AboutUsMobile from '../components/Mobile/AboutUs';
//actions
import { setMetaTags } from '../actions/homeActions';
//styles
import './style.css';


function AboutUs({ setMetaTags, data }){
  const pageProps = data?.prismic?.about_us;

  useEffect(() => {
    const description = 'Reset your password';
    const image = '';
    const url = window.location.href;
    const title = `SoBridely`;

    setMetaTags({
      description, image, url, title,
    });
  }, [setMetaTags]);
  return (
    <Layout
      pageData={pageProps}
      mobile={<AboutUsMobile/>}
      desktop={<AboutUsDesktop />} />
  );
}

const mapDispatchToProps = { setMetaTags };

export default connect(null, mapDispatchToProps)(AboutUs);

export const query = graphql`
query {
  prismic {
    about_us(lang: "en-us", uid: "about-us") {
      background_image
      call_to_action {
        button_text
        call_to_action_button_link
        call_to_action_image
        call_to_action_title
      }
      document_title
      title
      section_content {
        section_body
        section_image
        section_title
      }
    }
  }
}
`;